import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs'; 
@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  public baseUrl = environment.baseUrl + 'home/';
  constructor(private http: HttpClient) { }


 
  post(url: string, body: any) {
   var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    return this.http.post(this.baseUrl + url, body, { headers: reqHeader });
  }


  get(url: string) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
   return this.http.get(this.baseUrl + url, { headers: reqHeader });
  }

  getWithOutToken(url: string) {  
    return this.http.get(this.baseUrl + url);
   }


  

 

}
