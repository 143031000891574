import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs'; 
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public baseUrl = environment.baseUrl + 'admin/';
  constructor(private http: HttpClient) { }


 
  post(url: string, body: any) {
   var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    return this.http.post(this.baseUrl + url, body, { headers: reqHeader });
  }

  postWithOutToken(url: string, body: any) {    
     return this.http.post(this.baseUrl + url, body);
   }

  get(url: string) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
   return this.http.get(this.baseUrl + url, { headers: reqHeader });
  }


  getWithOutToken(url: string) {  
   return this.http.get(this.baseUrl + url);
  }

  uploadBanner(file,data):Observable<any> {     
    const formData = new FormData();  
    formData.append('status', data.status)
    formData.append('title', data.title)
    formData.append('banner', file);    
    const reqHeader = new HttpHeaders({   
      'Authorization': 'Bearer ' + localStorage.getItem('token')     
    });
    return this.http.post(this.baseUrl+'banner-upload',formData,{ headers: reqHeader }) 
  } 

  updateHomeDetails(file,data):Observable<any> {     
    const formData = new FormData();
    formData.append('programImage', file);
    formData.append('id', '1')
    formData.append('title', data.title)
    formData.append('about1', data.about1)
    formData.append('about2', data.about2)
    formData.append('note', data.note)
    formData.append('knowMore', data.knowMore)
    formData.append('query', data.query)
    formData.append('address', data.address)
    formData.append('twitter', data.twitter)
    formData.append('facebook', data.facebook)
    formData.append('youtube', data.youtube)
    formData.append('copyright', data.copyright)       
    const reqHeader = new HttpHeaders({   
      'Authorization': 'Bearer ' + localStorage.getItem('token')     
    });
    console.log(formData)
    return this.http.post(this.baseUrl+'home-detail-update',formData,{ headers: reqHeader }) 
  }

}
