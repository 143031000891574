import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  public Url = environment.baseUrl + 'result/';
  constructor(private http: HttpClient) { }

  get(method: string) {
    var reqHeader = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    })
    return this.http.get(this.Url + method, { headers: reqHeader });
  }

  post(method: string, body: any) {
    var reqHeader = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    })
    return this.http.post(this.Url + method, body, { headers: reqHeader });
  }
}
