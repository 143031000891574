import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/admin/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    },
    admin: true,
    member: false
  },
  {
    name: 'Membership',
    url: '/admin/organization',
    icon: 'icon-drop',
    admin: true,
    member: false
  },
  {
    name: 'Election Setup',
    url: '/admin/election',
    icon: 'icon-drop',
    admin: true,
    member: false
  }, {
    name: 'Candidate',
    url: '/admin/candidate',
    icon: 'icon-drop',
    admin: true,
    member: false
  },
  {
    name: 'Add Designation',
    url: '/admin/designation',
    icon: 'icon-drop',
    admin: true,
    member: false
  },
  {
    name: 'Manage Home Page',
    url: '/admin/home',
    icon: 'icon-drop',
    admin: true,
    member: false
  },
  // Here is all menu of the member
  {
    name: 'Dashboard',
    url: '/member/',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    },
    admin: false,
    member: true
  }
];
