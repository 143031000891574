import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../admin/admin.service';
import { ResultService } from '../../result/result.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  public electionResultData: any;
  constructor(private resultService: ResultService,
    private spinner: NgxSpinnerService, private adminService: AdminService) { }

  ngOnInit(): void {
    this.getResult();
    this.getActiveElection();
    this.getDesignations();
  }


  public electionData: any;
  getActiveElection() {
    this.adminService.getWithOutToken('get-active-election.json').subscribe((res: any) => {
      console.log(res);
      this.electionData = res.record;
    }, err => {
      console.log(err);
    });
  }

  public results: any = [];
  public designation = [];
  getResult() {
    this.spinner.show();
    this.resultService.get('get-result.json').subscribe((res: any) => {
      console.log(res)
      this.electionResultData = res;
     
       //this.designation = ['ICA-AP Regional Board Members', 'Chairman', 'ICA-AP Vice President', 'ICA-AP President','ICA Global Youth Member- Africa','ICA Global Youth Member at Large-Asia and Pacific','ICA Global Youth Member -Europe','ICA Global Youth Member -Americas','Youth Representative'];  
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    });

    console.log(this.designation)
  }

  getDesignations() {
    this.spinner.show();
    this.adminService.getWithOutToken('get-designations.json').subscribe((res: any) => {
      console.log(res)
      if(res){
          //res.sort((a, b) => a.name.localeCompare(b.name));
          res.forEach(element => {
              this.designation.push(element.name);
          });
      }
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    })
  }

  // short(dgn) {
  //   if (!this.electionResultData || !this.electionResultData.record) {
  //     return [];
  //   }   
  //   return this.electionResultData.record.filter(obj => obj.designation.name == dgn);
  // }

  short(dgn) {
    if (!this.electionResultData || !this.electionResultData.record) {
      return [];
    }
  
    // Filter by designation without changing order
    return this.electionResultData.record.filter(obj => obj.designation.name === dgn);
  }
  

  // short(dgn) {
  //   var value: any = [];
  //   value = this.electionResultData.record.filter(obj => {
  //     return obj.designation.name == dgn;
  //   });
  //   return value;
  // }
//   is_winned(val, i) {
//     var edl = this.electionResultData.election.election_details.find(x => x.designation == val.designation.id);
//     if (edl) {
//       if (i <= edl.no_of_posts) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }

//   }

//   hasDuplicateTotalVotes(results: any[], currentTotalVote: number, currentIndex: number): boolean {
//     for (let j = 0; j < results.length; j++) {
//         if (j !== currentIndex && results[j].total_vote === currentTotalVote ) {
//             return true; // If any other row has the same total vote, return true
//         }
//     }
//     return false; // If no other row has the same total vote, return false
// }

// Function to check for duplicate total votes
hasDuplicateTotalVotes(arr: any[], vote: number, index: number): boolean {
  return arr.some((item, idx) => item.total_vote === vote && idx !== index);
}

// Function to check if the candidate is a winner
  is_winned(val, i) {   
    if(val.total_vote > 0){
      var edl = this.electionResultData.election.election_details.find(x => x.designation == val.designation.id);
    
    if (edl) {
      if (i <= edl.no_of_posts) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    }   

  }

  is_vote_valid(total_vote: number): boolean {
    return total_vote > 1;
  }
  
 // Get the minimum vote count specifically for top candidates
  getMinimumVoteCount(): number | null {
    // Extract top candidates based on no_of_candidate limit
    const topCandidatesVotes = this.electionResultData.record
        .slice(0, this.electionResultData.no_of_candidate)
        .map(item => item.total_vote)
        .filter(vote => vote > 0); // Ignore 0 votes
    return topCandidatesVotes.length > 0 ? Math.min(...topCandidatesVotes) : null;
  }


  shouldShowCheckIcon(index: number): boolean {    
    return index < this.electionResultData.election.no_of_candidate;
  }

  shouldShowTimesIcon(index: number, totalVote: any) {
    const electionDetails = this.electionResultData.election.election_details;
    const result = electionDetails.find(detail => Number(detail.designation) === totalVote.designation.id);
    return totalVote === 0 || index >= result.no_of_posts; 
  }


  // getVoteColor(totalVote: any) {
  //   // Collect all total votes for the same designation
  //   const totalVotesArray = this.electionResultData.record
  //       .filter(record => record.designation.id === totalVote.designation.id)
  //       .map(record => record.total_vote);

  //   // Determine the frequency of each vote
  //   const voteFrequency = totalVotesArray.reduce((acc, vote) => {
  //       acc[vote] = (acc[vote] || 0) + 1;
  //       return acc;
  //   }, {} as Record<number, number>);

  //   // Identify the least vote count among duplicate votes
  //   const duplicates = Object.entries(voteFrequency)
  //       .filter(([_, count]) => typeof count === 'number' && count > 1)
  //       .map(([vote]) => Number(vote));

  //   const leastDuplicateVote = duplicates.length > 0 ? Math.min(...duplicates) : null;

  //   // Default values
  //   let color = '#c3edc3'; // Default green for valid votes
  //   let statusIcon = 'fa-check'; // Default "check" icon

  //   // Condition 1: If the vote is the least duplicate vote, return yellow without an icon
  //   if (totalVote.total_vote === leastDuplicateVote) {
  //       color = '#E9E74D'; // Yellow for least duplicate votes
  //       statusIcon = ''; // No icon
  //   }
  //   // Condition 2: If the vote is below the least duplicate vote, return red with a cross icon
  //   else if (leastDuplicateVote !== null && totalVote.total_vote < leastDuplicateVote) {
  //       color = '#f1d7d7'; // Red for votes below the least duplicate vote
  //       statusIcon = 'fa-times'; // "Cross" icon
  //   }

  //   return { color, statusIcon };
  // }

//   getVoteColor(totalVote: any) {
//     // Collect all total votes for the same designation
//     const totalVotesArray = this.electionResultData.record
//         .filter(record => record.designation.id === totalVote.designation.id)
//         .map(record => record.total_vote);

//     // Determine the frequency of each vote
//     const voteFrequency: Record<number, number> = totalVotesArray.reduce((acc, vote) => {
//         acc[vote] = (acc[vote] || 0) + 1;
//         return acc;
//     }, {} as Record<number, number>);

//     // Identify duplicate votes and the least among them
//     const duplicates = Object.entries(voteFrequency)
//         .filter(([_, count]) => count > 1) // Explicitly typed
//         .map(([vote]) => Number(vote));

//     const leastDuplicateVote = duplicates.length > 0 ? Math.min(...duplicates) : null;

//     // Find the minimum vote for the designation
//     const minVote = Math.min(...totalVotesArray);

//     // Default values
//     let color = '#c3edc3'; // Default green for valid votes
//     let statusIcon = 'fa-check'; // Default "check" icon

//     // Condition 1: If the vote is the least duplicate vote, return yellow without an icon
//     if (totalVote.total_vote === leastDuplicateVote) {
//         color = '#E9E74D'; // Yellow for least duplicate votes
//         statusIcon = ''; // No icon
//     }
//     // Condition 2: If the vote is the minimum and not a duplicate, return red with a cross icon
//     else if (leastDuplicateVote === null && totalVote.total_vote === minVote) {
//         color = '#f1d7d7'; // Red for the lowest vote
//         statusIcon = 'fa-times'; // "Cross" icon
//     }
//     // Condition 3: If the vote is below the least duplicate vote, return red with a cross icon
//     else if (leastDuplicateVote !== null && totalVote.total_vote < leastDuplicateVote) {
//         color = '#f1d7d7'; // Red for votes below the least duplicate vote
//         statusIcon = 'fa-times'; // "Cross" icon
//     }

//     return { color, statusIcon };
// }


getVoteColor(candidate: any, index: number) {
  // Get `no_of_posts` for the candidate's designation from the election details
  const electionDetails = this.electionResultData.election.election_details.find(
      detail => Number(detail.designation) === Number(candidate.designation.id)
  );

  // If no corresponding election detail is found, return default color
  if (!electionDetails) {
      return { color: '#ffffff', statusIcon: '' };
  }

  const noOfPosts = electionDetails.no_of_posts;

  // Get all candidates for the same designation
  const candidates = this.electionResultData.record.filter(
      record => record.designation.id === candidate.designation.id
  );

  // Sort candidates by total_vote in descending order
  const sortedCandidates = candidates.sort((a, b) => b.total_vote - a.total_vote);

  // Find the minimum vote count for the top `no_of_posts` candidates
  const minVoteForTopCandidates = sortedCandidates[noOfPosts - 1]?.total_vote;

  // Check if there is a tie in the last position for the top `no_of_posts`
  const isTieInTopCandidates = sortedCandidates[noOfPosts - 1]?.total_vote === sortedCandidates[noOfPosts]?.total_vote;

  // Default values
  let color = '#ffffff'; // Default white
  let statusIcon = '';   // No icon

  // If the candidate is in the top `no_of_posts`
  if (candidate.total_vote >= minVoteForTopCandidates) {
      // If there is a tie in the last position for the top `no_of_posts`
      if (isTieInTopCandidates && index >= noOfPosts - 1) {
          color = '#f1e3a1'; // Yellow for tied candidates
          statusIcon = '';   // No icon
      } else {
          color = '#c3edc3'; // Green for top candidates
          statusIcon = 'fa-check'; // Green check icon
      }
  } else {
      // Candidates who are not in the top `no_of_posts` get a red background with cross icon
      color = '#f8d7da'; // Red background for non-top candidates
      statusIcon = 'fa-times'; // Red cross icon for non-top candidates
  }

  return { color, statusIcon };
}








}
