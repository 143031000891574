import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public show_side_menu = true;
  public navItems: any = [];
  constructor(private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    var title = this.route.snapshot.data['title'];
    if(title == 'Member'){
      this.show_side_menu = false;
    }
   // debugger
    var navVal: any = [];
    navItems.forEach(function (val, index) {
      if (title == 'Member' || title == 'Result') {
        if (val.member && !val.admin) {
          navVal.push(val);
        }
      }
      if (title == 'Admin') {
        if (!val.member && val.admin) {
          navVal.push(val);
        }
      }
    });
    this.navItems = navVal;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
