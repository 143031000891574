import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin/admin.service';
import { Location } from '@angular/common';
import { WebsiteService } from '../website.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public bannerList : any = [];
  public homeDetails:any = [];
  constructor(private adminService: AdminService, public webService:WebsiteService,
    private location: Location) { }

  ngOnInit(): void {
    this.getActiveElection();
    this.getBannerList();
    this.getHomeData();
  }

  public electionData: any;
  getActiveElection() {
    this.adminService.getWithOutToken('get-active-election.json').subscribe((res: any) => {
      console.log(res);
      this.electionData = res.record;
    }, err => {
      console.log(err);
    });
  }

  getBannerList() {    
    this.webService.getWithOutToken('get-active-banner.json').subscribe((res: any) => {
      console.log(res);
      if (res.status == 'OK') {
        this.bannerList = res.record;        
      }      
    }, err => {     
      console.log(err);
    });
  }

  getHomeData(){
    this.webService.getWithOutToken('get-home-data.json').subscribe((res: any) => {
      console.log(res);
      if (res.status == 'OK') {
        this.homeDetails = res.record;        
      }     
    }, err => {      
      console.log(err);
    });
  }


  scroll($element) {
   
   $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
